import React from "react";

import { ReactComponent as CalendarIcon } from "./calendar.svg";

import moment from "moment";
import CalenderModalAtom from "atoms/CalenderModalAtom";
import TimeInputAtom from "atoms/TimeInputAtom";

import styles from "./styles.module.scss";

class CalenderAtom extends React.Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleTimePicker = this.toggleTimePicker.bind(this);
    this.closeTimePicker = this.closeTimePicker.bind(this);
    this.state = { showTimePicker: false, initialDate: null };
  }

  handleDateChange(date) {
    this.setState({ initialDate: date });
    console.log(new Date(date))
    this.props.onChange(new Date(date));
    this.props.submitEditedDate(new Date(date));
  }

  toggleTimePicker() {
    const { showTimePicker } = this.state;
    this.setState({ showTimePicker: !showTimePicker });
  }

  closeTimePicker() {
    const { showTimePicker } = this.state;
    this.setState({ showTimePicker: !showTimePicker });
  }

  render() {
    const { input, label } = this.props;
    return (
      <div className="pointer mr2" style={{ flex: "1" }}>
        {label && (
          <label htmlFor="name" className="f6 b db mb2">
            {label}
          </label>
        )}
        <div onClick={() => this.toggleTimePicker()}>
          <input
            className={styles["input-style"]}
            aria-describedby="name-desc"
            value={
              this.state.initialDate == null
                ? ""
                : moment(this.state.initialDate).format("MM/DD/YYYY")
            }
            placeholder="Select Date"
            disabled
          />
          <div
            style={{ height: "34px" }}
            className={`${styles["calendar-icon"]} ba b--black-20`}
          >
            <CalendarIcon width="16" height="16" />
          </div>
        </div>
        {/* <TimeInputAtom 
              input={input}
              value={this.state.initialDate == null ? "" : moment(this.state.initialDate).format('MM/DD/YYYY')}
              placeholder="Select Date"
            /> */}
        {this.state.showTimePicker && (
          <CalenderModalAtom
            isOpen={this.state.showTimePicker}
            toggle={this.closeTimePicker}
            input={
              this.state.initialDate == null
                ? ""
                : new Date(this.state.initialDate)
            }
            updateTime={this.handleDateChange}
          />
        )}
      </div>
    );
  }
}

export default CalenderAtom;
