import React from 'react';

import createHistory from 'history/createBrowserHistory';
import { Provider as ReduxProvider } from 'react-redux';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import ConnectedRouter from 'react-router-redux/ConnectedRouter';
import { BrowserRouter } from 'react-router-dom';
import { f1 } from "helpers/commonController.js";

import configureStore from 'helpers/configureStore';
import MandatoryFeedbackRoutes from 'pages/MandatorySurvey/Routes';
import FormsRoutes from 'pages/Forms/Routes'
import 'styles/index.global.scss';

const store = configureStore(
  // Server side rendering would have mounted our state on this global.
  window.__APP_STATE__,  // eslint-disable-line no-underscore-dangle
);
const history = createHistory();

document.addEventListener("message", (event) => {
  if(event.data){
    const params = event.data && JSON.parse(event.data);
    if(params.session_id){
      f1({ session_id: params.session_id , response_id : params.response_id});
    } 
  } 
}, false);

window.addEventListener("message", (event) => {
  if(event.data){
    const params = event.data && JSON.parse(event.data);
    if(params.session_id){
      f1({ session_id: params.session_id , response_id : params.response_id});
    }   
  } 
}, false);
const App = () => (
  <ReduxProvider store={store}>
    <ConnectedRouter history={history}>
    <BrowserRouter>
      <section>
        <Switch>
          <Route
            path="/"
            exact
            component={MandatoryFeedbackRoutes}
          />
          <Route
            path="/form/"
            exact
            component={FormsRoutes}
          />
        </Switch>
      </section>
      </BrowserRouter>
    </ConnectedRouter>
  </ReduxProvider>
);

export default App;