import React from "react";
import { Formik } from "formik";
import { Form, Button, Row } from "reactstrap";
import styles from "components/styles.module.scss";
import queryString from "query-string";
import { toast } from "react-toastify";

import SingleSelectComponent from "components/common/SingleSelectComponent";
import FreeTextComponent from "components/common/FreeTextComponent";
import NumberInputComponent from "components/common/NumberInputComponent";
import MultiSelectComponent from "components/common/MultiSelectComponent";
import MealSurveyComponent from "components/common/MealSurveyComponent";
import TimeSelectComponent from "components/common/TimeSelectComponent";
import FileUploadComponent from "components/common/FileUploadComponent";
import SignatureComponent from "components/common/SignatureComponent";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import imageName from "./brand_name.png";
import Logo from "./logo_blue.png";
import TRMSGS from "constants/Translations/Localization";
import Singleton from "lib/singleton";

class MandatoryFeedbackForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitStartTime = this.submitStartTime.bind(this);
    this.submitStartDate = this.submitStartDate.bind(this);
    this.state = { editStartDate: null, editStartTime: null };
  }

  componentWillMount() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    query && query.language
      ? TRMSGS.setLanguage(query.language)
      : TRMSGS.setLanguage("english");
  }

  async onSubmit(values, { setSubmitting, setErrors, resetForm }) {
    const singleton = new Singleton();
    const allocationStatusId = singleton.allocation_status_id;
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    const context = {
      context: {
        context_ids: {
          timecard_id: {},
          session_id: query.session_id && parseInt(query.session_id),
          job_ids: {},
        },
        user_journey: {},
        extra_data: "",
      },
    };
    try {
      const { mandatoryTemplate } = this.props;
      let questionsArr = [];
      mandatoryTemplate.questions &&
        mandatoryTemplate.questions.length &&
        mandatoryTemplate.questions.map((i) => {
          questionsArr.push(i.toJSON());
          return true;
        });
      questionsArr =
        questionsArr && questionsArr.filter((item) => item.answer !== null);
      const feedbackResponse = {
        template_id: mandatoryTemplate.template_id,
        questions: questionsArr,
      };
      await this.props.usecase.postFeedbackResponse({
        response: feedbackResponse,
        ...context,
        allocation_status_id: allocationStatusId
          ? +allocationStatusId
          : undefined,
      });
    } catch (e) {
      setErrors(e.errors);
      setSubmitting(false);
    }
  }

  submitStartTime(date) {
    const newDate = new Date();
    newDate.setHours(date.getHours());
    newDate.setMinutes(date.getMinutes());
    this.setState({ editStartTime: newDate });
  }

  submitStartDate(date) {
    const newDate = new Date();
    newDate.setDate(date.getDate());
    newDate.setMonth(date.getMonth());
    this.setState({ editStartDate: newDate });
  }

  render() {
    const {
      mandatoryTemplate,
      buttonLoading,
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    const language = TRMSGS.getLanguage();
    const startDate = query.start_date && query.start_date;
    return (
      <div>
        <Formik
          onSubmit={this.onSubmit}
          validator={() => ({})}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} autocomplete="off">
                <div className={styles["logo-styling"]}>
                  <img src={Logo} height="75" width="115" alt="logo" />
                  <br />
                  <img src={imageName} height="20" width="128" alt="name" />
                </div>
                <div className={`${styles["survey-heding"]} ml-3 mt-3`}>
                  {language == "english"
                    ? mandatoryTemplate.label
                    : language == "spanish" && mandatoryTemplate.spanishLabel
                    ? mandatoryTemplate.spanishLabel
                    : language == "spanish" &&
                      mandatoryTemplate.spanishLabel == undefined
                    ? mandatoryTemplate.label
                    : language == "bilingual" && mandatoryTemplate.spanishLabel
                    ? `${mandatoryTemplate.label} | ${mandatoryTemplate.spanishLabel}`
                    : mandatoryTemplate.label}
                </div>
                {startDate && (
                  <Row className="ml-0 mr-0">
                    <div
                      className={styles["divider_container"]}
                      style={{ width: "100%" }}
                    >
                      <span
                        className={styles["divider"]}
                        style={{ backgroundColor: "#09819A" }}
                      />
                      <span
                        className={styles["divider_text"]}
                        style={{ color: "#09819A" }}
                      >
                        {startDate}
                      </span>
                      <span
                        className={styles["divider"]}
                        style={{ backgroundColor: "#09819A" }}
                      />
                    </div>
                  </Row>
                )}
                {mandatoryTemplate &&
                  mandatoryTemplate.questions.length &&
                  mandatoryTemplate.questions.map((item, index) => {
                    switch (item.definition) {
                      case "single_select":
                        return (
                          <Row className="ml-0 mr-0">
                            <SingleSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "free_text":
                        return (
                          <Row className="ml-0 mr-0">
                            <FreeTextComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "boolean":
                        return (
                          <Row className="ml-0 mr-0">
                            <SingleSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "multi_select":
                        return (
                          <Row className="ml-0 mr-0">
                            <MultiSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              selectedOption={item.selectedOption}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "date_time_select":
                        return (
                          <Row className="ml-0 mr-0">
                            <MealSurveyComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              data={item}
                              submitStartDate={this.submitStartDate}
                              submitStartTime={this.submitStartTime}
                              submitEndDate={this.submitEndDate}
                              submitEndTime={this.submitEndTime}
                              language={language}
                            />
                          </Row>
                        );
                      case "time_select":
                        return (
                          <Row className="ml-0 mr-0">
                            <TimeSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              data={item}
                              submitStartDate={this.submitStartDate}
                              submitStartTime={this.submitStartTime}
                              submitEndDate={this.submitEndDate}
                              submitEndTime={this.submitEndTime}
                              language={language}
                            />
                          </Row>
                        );
                      case "SINGLE_SELECT":
                        return (
                          <Row className="ml-0 mr-0">
                            <SingleSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "FREE_TEXT":
                        return (
                          <Row className="ml-0 mr-0">
                            <FreeTextComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "NUMBER":
                        return (
                          <Row className="ml-0 mr-0">
                            <NumberInputComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "BOOLEAN":
                        return (
                          <Row className="ml-0 mr-0">
                            <SingleSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "MULTI_SELECT":
                        return (
                          <Row className="ml-0 mr-0">
                            <MultiSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              selectedOption={item.selectedOption}
                              handleChange={handleChange}
                              index={index}
                              isResponseWrong={item.wrongRes}
                              saveAnswers={item.setSelectedOption}
                              data={item}
                              language={language}
                            />
                          </Row>
                        );
                      case "DATE_TIME_SELECT":
                        return (
                          <Row className="ml-0 mr-0">
                            <MealSurveyComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              isResponseWrong={item.wrongRes}
                              index={index}
                              data={item}
                              submitStartDate={this.submitStartDate}
                              submitStartTime={this.submitStartTime}
                              language={language}
                            />
                          </Row>
                        );
                      case "DATE":
                        return (
                          <Row className="ml-0 mr-0">
                            <MealSurveyComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              isResponseWrong={item.wrongRes}
                              index={index}
                              data={item}
                              submitStartDate={this.submitStartDate}
                              language={language}
                            />
                          </Row>
                        );
                      case "TIME_SELECT":
                        return (
                          <Row className="ml-0 mr-0">
                            <TimeSelectComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              isResponseWrong={item.wrongRes}
                              index={index}
                              data={item}
                              submitStartDate={this.submitStartDate}
                              submitStartTime={this.submitStartTime}
                              language={language}
                            />
                          </Row>
                        );
                      case "SIGNATURE":
                        return (
                          <Row className="ml-0 mr-0">
                            <SignatureComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              isResponseWrong={item.wrongRes}
                              index={index}
                              data={item}
                              submitStartDate={this.submitStartDate}
                              submitStartTime={this.submitStartTime}
                              language={language}
                            />
                          </Row>
                        );
                      case "FILE_UPLOAD":
                        return (
                          <Row className="ml-0 mr-0">
                            <FileUploadComponent
                              errors={errors[item.sequenceOfConfiguration]}
                              handleChange={handleChange}
                              isResponseWrong={item.wrongRes}
                              index={index}
                              data={item}
                              submitStartDate={this.submitStartDate}
                              submitStartTime={this.submitStartTime}
                              language={language}
                            />
                          </Row>
                        );

                      default:
                        return <div></div>;
                    }
                  })}
                <div className={`${styles["btn-right"]} mt-4 mr-3 mb-4`}>
                  <Button
                    type="submit"
                    isColor="primary"
                    className={`${styles["survey-submit"]}`}
                    disabled={isSubmitting }
                  >
                    {TRMSGS.submit} <ButtonLoaderAtom active={buttonLoading} />
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

export default MandatoryFeedbackForm;
