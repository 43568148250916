import React from 'react';

import PropTypes from 'prop-types';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorised';

import MandatoryFeedbackPage from './MandatoryFeedback';
//import FormsPage from '../Forms/Forms'

const MandatoryFeedbackRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <MatchWhenAuthorized
        exact
        path={match.url}
        component={MandatoryFeedbackPage}
      />
    </Switch>
  );
};

MandatoryFeedbackRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MandatoryFeedbackRoutes;
