import MandatoryFeedbackUsecase from 'usecases/MandatoryFeedbackUsecase';
import MandatoryFeedbackAPIGateway from 'gateways/MandatoryFeedback';	

export function f1(params) {
  const responseId = params.response_id;
	const mandatoryFeedbackAPIGateway = new MandatoryFeedbackAPIGateway();
    const usecase = new MandatoryFeedbackUsecase(mandatoryFeedbackAPIGateway);
    const context = { "context": {
       "context_ids": {
           "timecard_id": {},
           "session_id": params.session_id,
           "job_ids": {}
       },
       "user_journey": {},
       "extra_data": ""
   	}
	}

    usecase.updateSessionIdToForm(context, responseId);
}