import React from 'react';

import styles from './styles.module.scss';

class TimePickerAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }


   handleChange(e) {
    const { input } = this.props;
    input.onChange(e.target.value);
  }

  render() {
    const { value, input, placeholder } = this.props;
    return (
      <div style={{cursor: 'pointer', display: 'inline'}}>
        <input
          className={styles['input-style']}
          aria-describedby="name-desc"
          value={value}
          onChange = {this.props.onChange}
          placeholder={placeholder}
          />
      </div>
    );
  }
}

export default TimePickerAtom;