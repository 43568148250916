import { routerReducer } from 'react-router-redux';
import combineReducers from 'redux/lib/combineReducers';
import { reducer as formReducer } from 'redux-form';


const appReducer = combineReducers({
  form: formReducer,
  router: routerReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  return appReducer(newState, action);
};

export default rootReducer;
