import React from 'react';

import {ReactComponent as ClockIcon} from './clock.svg';

import TimeKeeperAtom from 'atoms/TimeKeeperAtom';
import TimeInputAtom from 'atoms/TimeInputAtom';

import styles from './styles.module.scss';

class TimePickerAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleTimePicker = this.toggleTimePicker.bind(this);
    this.closeTimePicker = this.closeTimePicker.bind(this);
    this.state = { showTimePicker : false , initialTime : null};
  }

   handleDateChange(date) {
    this.setState({initialTime : date.formatted12});
    const startNewDay = new Date();
    startNewDay.setHours(date.hour);
    startNewDay.setMinutes(date.minute);
    this.props.onChange(new Date(startNewDay));
    this.props.submitEditedTime(new Date(startNewDay));
  };

  toggleTimePicker(){
    const { showTimePicker} = this.state;
    this.setState({showTimePicker : !showTimePicker});
  }

  closeTimePicker(){
    const { showTimePicker } = this.state;
    this.setState({showTimePicker : !showTimePicker});
  }


  render() {
    const { input , label } = this.props;
    return (
      <div className ="pointer" style={{flex:'1'}}>
        {label && <label htmlFor="name" className="f6 b db mb-2">{label}</label>}
          <TimeInputAtom 
            input={input}
            value={this.state.initialTime  == null ? "" : this.state.initialTime}
            placeholder="Select Time"
          />
        <div style={{height : '34px'}} className ={`${styles['calendar-icon']} ba b--black-20`} onClick={() => this.toggleTimePicker()}> 
          <ClockIcon width="16" height="16"/>
        </div>
        {this.state.showTimePicker &&
          <TimeKeeperAtom
            isOpen={this.state.showTimePicker}
            toggle={this.closeTimePicker}
            input={this.state.initialTime}
            updateTime={this.handleDateChange}
          />
        }
      </div>
    );
  }
}

export default TimePickerAtom;