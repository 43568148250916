import React from 'react';

import { reduxForm } from 'redux-form';

// import Timekeeper from 'react-timekeeper';
import Modal from 'react-bootstrap-modal';

const TimeKeeperAtom = (props) =>  {
  const { isOpen , toggle } = props;

  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      backdrop="static"
      className= "edit_time_popup"
      onHide={() => toggle()}
    >
    <form>
      <Modal.Body>
        {/* <Timekeeper
          time={props.input}
          onChange = {(val) => props.updateTime(val)}
          closeOnMinuteSelect ={false}
          switchToMinuteOnHourSelect={true}
          doneButton={() => (
            <div
              style={{ textAlign: 'center', padding: '16px 0', color: '#09819a', cursor: 'pointer', borderTop: '1px solid #CCC', letterSpacing: '0.5px', background: '#f4f4f4' }}
              onClick={() => props.toggle()}
            >
              DONE
            </div>
          )}
          config={{
            TIME_BACKGROUND: '#162d6e',
            FONT_FAMILY: '"Open Sans", sans-serif',
            TIME_SELECTED_COLOR: 'white',
            TIME_DEFAULT_COLOR : 'white',
            CLOCK_WRAPPER_MERIDIEM_TEXT_COLOR_SELECTED : 'white',
            CLOCK_WRAPPER_MERIDIEM_BACKGROUND_COLOR_SELECTED : '#162d6e',
            DONE_BUTTON_COLOR: '#09819a',
            CLOCK_WRAPPER_MERIDIEM_BACKGROUND: '#f8fcfd'
          }}
          // ...
        /> */}
      </Modal.Body>
      </form>
    </Modal>
  );
};


export default reduxForm({
  form: 'TimeKeeperForm',
  enableReinitialize: true,
})(TimeKeeperAtom);

