// import createHistory from 'history/createBrowserHistory';
import routerMiddleware from 'react-router-redux/middleware';
import { createStore, applyMiddleware, compose } from 'redux';
// import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';

import reducer from './ducks';

// Create a history of your choosing (we're using a browser history in this case)
// const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
// const middleware = routerMiddleware();

// const logger = createLogger({
//   level: 'info',
//   collapsed: true,
// });

function configureStore(initialState = undefined) {
  const enhancers = compose(
    // Middleware store enhancer.
    applyMiddleware(
      promiseMiddleware(),
      routerMiddleware(),
      // logger,
    ),
    // Redux Dev Tools store enhancer.
    process.env.NODE_ENV === 'development'
      && typeof window !== 'undefined'
      && typeof window.devToolsExtension !== 'undefined'
      // Call the brower extension function to create the enhancer.
      ? window.devToolsExtension()
      // Else we return a no-op function.
      : f => f,
  );

  const store = initialState
    ? createStore(reducer, initialState, enhancers)
    : createStore(reducer, enhancers);

  return store;
}

export default configureStore;
