import React, { useRef } from "react";
import { Row, Col, Card, FormText, FormGroup, Button } from "reactstrap";
import SignaturePad from "react-signature-canvas";
//import CalenderAtom from "atoms/CalenderAtom";
//import TimePickerAtom from 'atoms/TimePickerAtom';
import styles from "components/styles.module.scss";
import { convertToBase64, dataURLtoFile } from "utills/index.js";

const SignatureComponent = (props) => {
  const {
    data: {
      data,
      data: { answer },
    },
    index,
    isResponseWrong,
    language,
    errors,
  } = props;
  const sigRef = useRef();
  const clearSignature = () => {
    sigRef.current.clear();
    props.data.setSelectedOption({ ...answer[0], value: undefined });
    // setSignature(null);
  };

  const handleSignatureEnd = async () => {
    const dataUrlImg = sigRef.current.getTrimmedCanvas().toDataURL("image/png");
    const imageFile = dataURLtoFile(dataUrlImg, "signature");
    props.data.setSelectedOption({
      ...answer[0],
      value: await convertToBase64(imageFile),
    });
    // setSignature(sigRef.current.toDataURL());
  };
  return (
    <Card
      className={
        isResponseWrong
          ? `${styles["highlight-div"]} ${styles["card-ui"]} m-3 p-3`
          : `${styles["card-ui"]} m-3 p-3`
      }
    >
      <Row>
        <Col md={11} className={`${styles["custom-col"]} pl-1`}>
          <span>
            <span>
              {data.behaviour && data.behaviour === "MANDATORY" ? "*" : null}
            </span>
            {index + 1}.
          </span>
          <div
            className={
              data.behaviour && data.behaviour === "MANDATORY"
                ? `${styles["questions-ui"]} pr-0 pl-2`
                : "pl-2"
            }
            dangerouslySetInnerHTML={{
              __html:
                language === "english"
                  ? data.question
                  : language === "spanish" && data.sp_question
                  ? data.sp_question
                  : language === "spanish" && data.sp_question === undefined
                  ? data.question
                  : language === "bilingual" && data.sp_question
                  ? `${data.sp_question} ${data.question}`
                  : data.question,
            }}
          ></div>
        </Col>
      </Row>
      <div className={`${styles["options-margin"]} mt-2 custom-input-label`}>
        <FormGroup>
          {
            <SignaturePad
              penColor={"#000000"}
              maxWidth={2}
              canvasProps={{ className: `${styles["SigPad"]}` }}
              ref={sigRef}
              onEnd={handleSignatureEnd}
              clearOnResize={false}
            />
          }
          <div>
            <Button
              isColor="primary"
              onClick={clearSignature}
              className={`${styles["clear-signature"]}`}
            >
              Clear
            </Button>
          </div>
        </FormGroup>

        <div>
          <FormText style={{ fontSize: "14px" }} color="danger">
            {errors}
          </FormText>
        </div>
      </div>
    </Card>
  );
};

export default SignatureComponent;
