import React from 'react';

import Route from 'react-router-dom/Route';

class MatchWhenAuthorized extends React.Component {
  constructor(props) {
    super(props);
    this.checkProfile = this.checkProfile.bind(this);
  }

  checkProfile(props) {
    const { component: Component } = this.props;
    return <Component {...props} />;
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => this.checkProfile(props)}
      />
    );
  }
}


export default MatchWhenAuthorized;
