import ApiGateway from "lib/api-gateway";
import AppConfig from "helpers/config";
import MandatoryFormEntity from "entities/mandatoryFeedback";

class MandatoryFeedbackAPIGateway {
  toString() {
    return "MandatoryFeedbackAPIGateway";
  }

  async getMandatoryQuestions(params) {
    const listConfig = AppConfig.server.apiHost + "/feedback/template/";
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load(params);
    const entity =
      response.data.results &&
      response.data.results.length &&
      response.data.results[0].template_data &&
      response.data.results[0].template_data.questions.length &&
      response.data.results[0].template_data.questions.map(
        (i) => new MandatoryFormEntity(i)
      );
    return {
      questions: entity,
      template_id: response.data.results.length && response.data.results[0].id,
      label:
        response.data.results &&
        response.data.results.length &&
        response.data.results[0].template_data &&
        response.data.results[0].template_data.label,
      spanishLabel:
        response.data.results &&
        response.data.results.length &&
        response.data.results[0].template_data &&
        response.data.results[0].template_data.sp_label &&
        response.data.results[0].template_data.sp_label,
    };
  }
 
  async getFormDetails(id) {
    const listConfig = AppConfig.server.apiHost + `/feedback/template/${id}/`;
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load();
    const entity =
      response.data &&
      response.data.template_data &&
      response.data.template_data.questions.length &&
      response.data.template_data.questions.map(
        (i) => new MandatoryFormEntity(i)
      );
    return {
      questions: entity,
      template_id: response.data && response.data.id,
      label:
        response.data &&
        response.data.template_data &&
        response.data.template_data.label,
      spanishLabel: "",
    };
  }

  async postFeedback(data) {
    const listConfig = AppConfig.server.apiHost + "/feedback/response/";
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.save(data);
    return response;
  }

  async updateSessionId(data) {
    const { responseId, ...rest } = data;
    const listConfig =
      AppConfig.server.apiHost + "/feedback/response/" + responseId + "/";
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.partialUpdate(rest);
    return response;
  }
}
export default MandatoryFeedbackAPIGateway;
