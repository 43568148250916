
const {
  REACT_APP_APPLICATION_BASE_URL,
  REACT_APP_CLIENT_ID_BASE_URL,
  REACT_APP_CLIENT_SECRET_BASE_URL,
} = process.env;

const config = {
  homepage: 'http://thoughts2binary.com',

  // Web analytics settings
  analytics: {
    google: { trackingID: 'UA-XXXXX-Y' },
  },

  // Backend Server Configs
  server: {
    apiHost: REACT_APP_APPLICATION_BASE_URL,
    ClientId: REACT_APP_CLIENT_ID_BASE_URL,
    ClientSecret: REACT_APP_CLIENT_SECRET_BASE_URL,
  },
};

module.exports = config;
