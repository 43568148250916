import React from 'react';
import { Formik, Field } from 'formik';
import { Row, Col, Form, Card, FormText} from 'reactstrap';

import CalenderAtom from 'atoms/CalenderAtom';
//import TimePickerAtom from 'atoms/TimePickerAtom';
import styles from 'components/styles.module.scss';

const MealSurveyComponent = (props) => {
  const { initialValues, data : { data, data : { answer }, selectedOption}, index, isResponseWrong, language, errors } = props;
  return (
    <Card className= {isResponseWrong ? `${styles['highlight-div']} ${styles['card-ui']} m-3 p-3` : `${styles['card-ui']} m-3 p-3`}>
        <Formik
          onSubmit={props.onSubmit}
          validator={() => ({})}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            setFieldValue,
          }) => {
            return (
              <div>
                <Form onSubmit={handleSubmit} autocomplete="off">
                  <Row>
                    <Col md={11} className={`${styles['custom-col']} pl-1`}>
                      <span><span>{data.behaviour && data.behaviour === 'MANDATORY' ? '*' : null}</span>{index + 1}.</span>
                      <div className={data.behaviour && data.behaviour === 'MANDATORY' ? `${styles['questions-ui']} pr-0 pl-2` : 'pl-2'} dangerouslySetInnerHTML={{ __html: (language == 'english' ? data.question : language == 'spanish' && data.sp_question ? data.sp_question : language == 'spanish' && data.sp_question == undefined ? data.question : language == 'bilingual' && data.sp_question ? `${data.sp_question} ${data.question}` : data.question ) }}></div>
                    </Col>
                  </Row>
                  <div className="mt-2">
                    <Row>
                      <Col>
                        <Field
                            component={CalenderAtom}
                            values = {initialValues}
                            id={`date_${index}`}
                            name={`date_${index}`}
                            submitEditedDate={props.submitStartDate}
                            handleChange={handleChange}
                            onChange={(val) =>props.data.setSelectedOption({...selectedOption[0],date: val})}
                        />
                      </Col>
                      <Col>
                        {/* <Field
                            component={TimePickerAtom}
                            values = {initialValues}
                            id={`time_${index}`}
                            name={`time_${index}`}
                            submitEditedTime = {props.submitStartTime}
                            handleChange={handleChange}
                            onChange={(val) =>props.data.setSelectedOption({...selectedOption[0],time:val})}
                        /> */}
                      </Col>
                    </Row>
                  </div>
                </Form>
                <div><FormText  style={{fontSize: '14px'}} color="danger">{props.errors}</FormText></div>  
              </div>
            )
          }}
        />
      </Card>
  );
};


export default MealSurveyComponent;
