import React from 'react';

import { FormGroup, Card, CustomInput, Row, Col, FormText } from 'reactstrap';
import styles from 'components/styles.module.scss';

const MultiSelectComponent = (props) => {
  const { data: { data }, index, isResponseWrong, selectedOption, errors, language } = props;
  console.log('data', data)
  return (
    <Card className={isResponseWrong ? `${styles['highlight-div']} ${styles['card-ui']} m-3 p-3` : `${styles['card-ui']} m-3 p-3`}>
      <Row>
        <Col md={11} className={`${styles['custom-col']} pl-1`}>
          <span><span>{data.behaviour && data.behaviour === 'MANDATORY' ? '*' : null}</span>{index + 1}.</span>
          <div className={data.behaviour && data.behaviour === 'MANDATORY' ? `${styles['questions-ui']} pr-0 pl-2` : 'pl-2'} dangerouslySetInnerHTML={{ __html: (language == 'english' ? data.question : language == 'spanish' && data.sp_question ? data.sp_question : language == 'spanish' && data.sp_question == undefined ? data.question : language == 'bilingual' && data.sp_question ? `${data.sp_question} ${data.question}` : data.question ) }}></div>
        </Col>
      </Row>
      <div className={`${styles['options-margin']} mt-2 custom-input-label`}>
        {
          data && data.answer && data.answer.length && data.answer.map((item, id) =>
            <FormGroup>
              <CustomInput
                type="checkbox"
                id={`${index}_${id}`}
                name={language == 'english' ? item.value : language == 'spanish' && item.sp_value ? item.sp_value : language == 'spanish' && item.sp_value == undefined ? item.value : language == 'bilingual' && item.sp_value ? `${item.sp_value} | ${item.value}` : item.value}
                label={language == 'english' ? item.value : language == 'spanish' && item.sp_value ? item.sp_value : language == 'spanish' && item.sp_value == undefined ? item.value : language == 'bilingual' && item.sp_value ? `${item.sp_value} | ${item.value}` : item.value}
                onChange={(val) => { props.handleChange(val); props.data.setSelectedOption(item, 'multi') }}
              />
            </FormGroup>
          )
        }
        <div><FormText style={{ fontSize: '14px' }} color="danger">{errors}</FormText></div>
      </div>
    </Card>
  );
};

export default MultiSelectComponent;
