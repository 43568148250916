import React from 'react';
import queryString from 'query-string';
import Singleton from 'lib/singleton';
import MandatoryFeedbackFormComponent from 'components/MandatorySurvey/MandatoryFeedbackForm';

import MandatoryFeedbackUsecase from 'usecases/MandatoryFeedbackUsecase';
import MandatoryFeedbackAPIGateway from 'gateways/MandatoryFeedback';

import Loader from 'images/Logo.gif';
import styles from './styles.module.scss';
import SectionLoaderAtom from 'atoms/SectionLoader';
//Mandatory feedback form 
class FormsPage extends React.Component {

  constructor(props){
    super(props)
    this.fetchData = this.fetchData.bind(this);
    this.listenMandatoryFeedbackFormUsecase = this.listenMandatoryFeedbackFormUsecase.bind(this);
    this.updateState = this.updateState.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    const mandatoryFeedbackAPIGateway = new MandatoryFeedbackAPIGateway();
    this.usecase = new MandatoryFeedbackUsecase(mandatoryFeedbackAPIGateway);
    this.singleton = new Singleton();
    this.state = { mandatoryTemplate : {} , showLoader : false , buttonLoading: false}
  }

  componentWillMount(){
    const {  location: { search } } = this.props;
    const query = queryString.parse(search);
    this.fetchData();
    this.singleton.language = query && query.language ? query.language : 'english'
  }


  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

  startTimer() {
    this._timeOut = setTimeout(() => {
      window.parent.postMessage(JSON.stringify({ message: 'Logout' }), "*");
    }, 20000);
  }

  fetchData(){
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    this.singleton.oAuthToken = query.oAuthToken;
    this.singleton.company_id = query.company_id;
    this.singleton.uniqueUuid = query.uniqueUuid;
    this.singleton.employeeId = query.employeeId;
    this.singleton.formId = query.formId
    this.singleton.allocation_status_id =query.allocation_status_id
    this.listenMandatoryFeedbackFormUsecase();
    this.usecase.getFormDetails(query.formId);
  }

  componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }


  listenMandatoryFeedbackFormUsecase() {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'GET_MANDATORY_FEEDBACK_QUESTIONS':
          if(!event.data.questions){
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({type: "click", action: 'SUCCESS'}))       
            window.parent.postMessage(JSON.stringify({ message: 'No questions found' , response_id : '0', action: 'SUCCESS', screenContext : query.screen_context }), "*");          
          }
          this.updateState('mandatoryTemplate', event.data);
          break;
        case 'POST_DATA_SUCCESS':
          window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({type: "click", message : event.data.message ? event.data.message : null, response_id : event.data.response_id, action: event.data.action_code ? event.data.action_code : 'SUCCESS'}))       
          window.parent.postMessage(JSON.stringify({ message: event.data.message , response_id : event.data.response_id, action: event.data.action_code ? event.data.action_code : 'SUCCESS', screenContext : query.screen_context }), "*");          
          if(event.data && event.data.action_code === 'FAILED'){
            const failedQuestions = event.data.response && event.data.response.failed_questions;
            const arr = failedQuestions.map((i)=>i.sequence_of_configuration)
            this.state.mandatoryTemplate && this.state.mandatoryTemplate.questions && this.state.mandatoryTemplate.questions.length && this.state.mandatoryTemplate.questions.forEach((i)=>{
              if(arr.find((item)=> item === i.sequenceOfConfiguration)){
                i.isResponseWrong();
              }
              else{
                i.resetForm();
              }
            });
          }
          else{
             this.state.mandatoryTemplate && this.state.mandatoryTemplate.questions && this.state.mandatoryTemplate.questions.length && this.state.mandatoryTemplate.questions.forEach((i)=>{             
                i.resetForm();
            });
          }
          break;
        case 'UPDATE_SESSION_SUCCESS':
          console.log('update', event)
          break;
        case 'SHOW_LOADER':
          this.updateState('showLoader', true);
          break;
        case 'HIDE_LOADER':
          this.updateState('showLoader', false);
          break;
        case 'SHOW_BUTTON_LOADER':
          this.setState({buttonLoading: true});
          break;
        case 'HIDE_BUTTON_LOADER':
          this.setState({buttonLoading: false});
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    const { showLoader } = this.state;
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    if(showLoader && query.context === 'kiosk') return <SectionLoaderAtom active/>
    if(showLoader) return <div className={`${styles['loader-aligment']}`}><img src={Loader} alt="loading..." height={100}/></div>
    return(
      <div>
          <MandatoryFeedbackFormComponent
            location={this.props.location}
            mandatoryTemplate={this.state.mandatoryTemplate}
            usecase={this.usecase}
            showLoader={this.state.showLoader}
            buttonLoading={this.state.buttonLoading}
          />
      </div>
    );
  }
}

export default FormsPage;
